import React, { useCallback, useState } from "react"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import { ACCOUNT_DELETION_URL } from "../api_urls"
import Layout from "../components/Layout"
import Head from "../components/Head"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Modal from "../components/Modal"
import { isValidEmail } from "../is_valid_email"

type ErrorType = "API_ERROR" | "EMAIL_ERROR"

const AccountDeletion = () => {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ErrorType | undefined>()

  const [email, setEmail] = useState("")
  const [wasEmailSent, setWasEmailSent] = useState(false)

  const changeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!wasEmailSent) {
        setError(undefined)
        setEmail(event.target.value)
      }
    },
    [email, setEmail, wasEmailSent, setError]
  )

  const sendEmail = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      setError(undefined)

      if (!isValidEmail(email)) {
        setError("EMAIL_ERROR")
        return
      }

      setIsLoading(true)

      fetch(ACCOUNT_DELETION_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then(() => {
          setWasEmailSent(true)
        })
        .catch(() => {
          setError("API_ERROR")
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [email, error, setWasEmailSent, setIsLoading]
  )

  const redirectToHome = useCallback(() => {
    navigate("/")
  }, [])

  return (
    <Layout>
      <Head title="Account deletion - Muun" description="" />
      <div className="account-deletion page">
        <Header className="light" />
        <div className="content container">
          <h1>
            <FormattedMessage id="account_deletion_title" />
          </h1>
          <p>
            <FormattedMessage id="account_deletion_description_1" />
            &nbsp;
            <code>
              <FormattedMessage id="account_deletion_description_settings" />
            </code>
            .
            <br />
            <br />
            <FormattedMessage id="account_deletion_description_2" />
          </p>
          <div className="form">
            <form onSubmit={sendEmail} autoComplete="off">
              <input
                type="text"
                name="email"
                className="input-submit"
                placeholder={intl.formatMessage({ id: "your_email_address" })}
                disabled={wasEmailSent}
                value={email}
                onChange={changeEmail}
                required
              />
              <button
                type="submit"
                className={classNames("btn-submit", { loading: isLoading })}
                disabled={wasEmailSent || isLoading}
              >
                <span className="btn-text">
                  {wasEmailSent ? (
                    <FormattedMessage id="success" />
                  ) : (
                    <FormattedMessage id="enter" />
                  )}
                </span>
              </button>
            </form>
          </div>

          {error === "EMAIL_ERROR" ? (
            <div className="message error">
              ⛔ Please enter a valid email address
            </div>
          ) : error === "API_ERROR" ? (
            <div className="message error">
              ⛔ <FormattedMessage id="account_deletion_error" />
              &nbsp;
              <Link to="/contact">
                <FormattedMessage id="contact_us" />
              </Link>
              .
            </div>
          ) : null}

          <Modal show={wasEmailSent} onClose={redirectToHome}>
            <div className="content">
              <h2>
                <FormattedMessage id="account_deletion_success_title" />
              </h2>
              <p>
                <FormattedMessage id="account_deletion_success_description_1" />
                <br />
                <FormattedMessage id="account_deletion_success_description_2" />
              </p>
            </div>
          </Modal>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default AccountDeletion
